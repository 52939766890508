
export const data =
{
    "projects": [
        {
            "title": "Modernes Einfamilienhaus in Heiligensee",
            "status": "Im bau",
            "description": `
            <section>
                <p>Unser modernes Neubauprojekt an der <strong>Helmkrautstraße, Berlin Heiligensee</strong> vereint luxuriöses Wohnen mit nachhaltiger Bauweise. Das in hochwertigem Bauhausstil errichtete Wohnhaus bietet auf <strong>239 m² Wohnfläche (davon 162,3 m² ohne Keller)</strong> plus einer großzügigen <strong>37 m² Terrasse</strong> und einem <strong>ca. 500m² großem Grundstück</strong> den perfekten Raum für Ihre individuellen Ansprüche.</p>
                <p>Geplante Fertigstellung: <strong>Januar 2025</strong></p>
                </section>
            <section>
                <h2>Highlights des Projekts:</h2>
                <ul>
                    <li><strong>Zimmeranzahl:</strong> 9 Zimmer, 3 Bäder und ein voll ausgebauter Keller, ideal für Familien oder als repräsentativer Wohnsitz.</li>
                    <li><strong>Luxuriöser Standard:</strong> Hochwertige Materialien und exklusive Innenausstattung sorgen für ein erstklassiges Wohnerlebnis.</li>
                    <li><strong>Energieeffizienz:</strong> Ausgestattet mit einer modernen Wärmepumpe und dreifach verglasten Fenstern, erfüllt das Haus höchste energetische Standards.</li>
                    <li><strong>Smart-Home-Technologie:</strong> Innovative Technik für Komfort und Sicherheit.</li>
                    <li><strong>Flexible Raumgestaltung:</strong> Offene Grundrisse mit lichtdurchfluteten Räumen schaffen eine harmonische Atmosphäre.</li>
                    <li><strong>Familienfreundlichkeit:</strong> Zwei Kinderzimmer mit Bad und großzügige Freiflächen bieten Raum für Entfaltung.</li>
                    <li><strong>Große Terrasse:</strong> Perfekt für entspannte Stunden im Freien.</li>
                </ul>
            </section>
            <section>
                <h2>Außenbereich:</h2>
                <p>Die moderne Architektur wird durch großzügige Gartenflächen ergänzt, die Privatsphäre und Erholung garantieren. Das Bauprojekt ist zudem auf eine nachhaltige Solaranlage ausgelegt, die auf dem Dach installiert werden kann – ein Schritt in Richtung einer umweltfreundlichen Zukunft.</p>
            </section>
            <section>
                <h2>Standortvorteile:</h2>
                <p>Die Lage in Berlin-Heiligensee kombiniert Ruhe und Naturverbundenheit mit einer exzellenten Anbindung an die Stadt. Schulen, Einkaufsmöglichkeiten und Freizeitangebote sind schnell erreichbar.</p>
            </section>
            <footer>
                <p><strong>Sind Sie interessiert an diesem exklusiven Projekt?</strong> Kontaktieren Sie uns und erfahren Sie mehr über Ihr zukünftiges Zuhause!</p>
                <p><strong>Kontakt:</strong> <a href="mailto:verwaltung@bdg-wohnbau.de">verwaltung@bdg-wohnbau.de</a></p>
            </footer>
            </br>  
            `,
            "titleImg": "./public_images/projects/heiligensee-front/vorne_rechts.jpg",
            "images": [
                "./public_images/projects/heiligensee-front/vorne_links.jpg",
                "./public_images/projects/heiligensee-front/hinten_links.jpg",
                "./public_images/projects/heiligensee-front/hinten_rechts.jpg",
                "./public_images/projects/heiligensee-front/vogel.jpg",
                "./public_images/projects/heiligensee-front/EG-Umbau.png",
                "./public_images/projects/heiligensee-front/OG-Umbau.png",
                "./public_images/projects/heiligensee-front/Keller-Umbau.png"
            ]
        },
        {

            "title": "Villa #2 in Berlin Zehlendorf",
            "status": "Abgeschlossen",
            "description": `
                <div>Unsere neue Luxusvilla in Berlin Zehlendorf ist ein wahrhaft beeindruckendes Bauwerk, das Komfort, Eleganz und Modernität auf perfekte Weise vereint. Die Villa wird mit größter Sorgfalt und Liebe zum Detail gebaut und verfügt über alle Annehmlichkeiten, die man sich nur wünschen kann.
                </div>
                </br> 
                <div>Beim Betreten der Villa fällt sofort die großzügige Eingangshalle auf, die mit ihrem hohen Decken und dem eleganten Marmorboden einen luxuriösen Eindruck vermittelt. Von hier aus gelangt man direkt in den offenen Wohnbereich, der sich über das gesamte Erdgeschoss erstreckt und durch seine raumhohen Fenster viel Tageslicht einfängt.
                </div >
                </br> 
                <div>Die moderne Küche ist mit den neuesten Geräten ausgestattet und bietet genügend Platz zum Kochen und Entspannen. Von hier aus gelangt man auch in den Essbereich, der mit einem großen Esstisch ausgestattet ist und sich perfekt für formelle Abendessen oder Familienfeiern eignet.
                </div >
                </br> 
                <div>Das Obergeschoss ist über eine elegante Treppe zu erreichen und bietet genügend Platz für bis zu drei geräumige Schlafzimmer, die jeweils mit eigenem Badezimmer und Garderobe ausgestattet sind. Die Badezimmer sind mit hochwertigen Armaturen und Fliesen ausgestattet und vermitteln ein luxuriöses Ambiente.
                </div >
                </br> 
                <div>An der Seite des Gebäudes befindet sich eine Garage, in der ein Auto und mehrere Fahrräder untergebracht werden können. Es gibt auch genügend Stauraum für Werkzeuge und andere Gegenstände.
                </div>
                </br> 
                <div>Der Garten rund um die Villa ist ebenfalls beeindruckend und bietet viel Platz zum Entspannen und Genießen. Es gibt eine große Terrasse, auf der man im Sommer grillen und essen kann, sowie einen gepflegten Rasen und eine Vielzahl von Blumen und Sträuchern.
                </div>
                </br> 
                <div>Insgesamt ist die Villa in Berlin Zehlendorf ein echtes Juwel, das Luxus, Eleganz und Komfort auf perfekte Weise vereint. Von der Lage am Heinrich-Laehr-Park über die Ausstattung bis hin zum Design ist alles einfach perfekt durchdacht und lässt keine Wünsche offen.
            `,
            "titleImg": "./public_images/projects/begej-priv2/BG1-4.jpg",
            "images": [
                "./public_images/projects/begej-priv2/BG1-4.jpg",
                "./public_images/projects/begej-priv2/BG2-4.jpg",
                "./public_images/projects/begej-priv2/BG3-4.jpg",
            ]
        },
        {
            "title": "Poolvilla in Berlin Zehlendorf",
            "status": "Verkauft",
            "description": `
            <div> Das luxuriöse Einfamilienhaus in Berlin Zehlendorf ist eine beeindruckende Residenz, die modernen Komfort mit traditionellem Charme vereint. Das Anwesen erstreckt sich über einen großzügigen Grundstück mit einem atemberaubenden, gepflegten Garten.
            </div>
            </br>  
            <div> Beim Betreten des Hauses werden Sie von einer eleganten Eingangshalle begrüßt, die den Ton für den gesamten Wohnbereich setzt. Die Wohnfläche ist großzügig und stilvoll eingerichtet, mit hellen Farben und hochwertigen Oberflächen, die eine elegante und einladende Atmosphäre schaffen. Der offene Grundriss des Wohnzimmers und der Essbereich bietet einen nahtlosen Übergang zwischen den verschiedenen Räumen und verfügt über große Fenster, die reichlich Tageslicht und einen atemberaubenden Blick auf den Garten bieten.
                Die Küche ist komplett ausgestattet mit modernen Geräten und edlen Oberflächen und bietet viel Platz zum Kochen und Unterhalten. Eine Tür führt direkt auf die geräumige Terrasse, die perfekt zum Entspannen und Essen im Freien ist.
            </div>
            </br>  
            <div> Das Haus verfügt über fünf geräumige Schlafzimmer, darunter ein beeindruckendes Hauptschlafzimmer mit en-suite Badezimmer und Ankleidezimmer. Die Badezimmer sind alle mit hochwertigen Armaturen und Fliesen ausgestattet.
    	        Das Highlight des Anwesens ist jedoch der atemberaubende Garten, der einen großen, beheizten Pool umfasst, sowie eine Sonnenterrasse und einen Grillplatz. Der Garten ist vollständig eingezäunt, um die Privatsphäre und Sicherheit zu gewährleisten.
            </div> 
            </br> 
            <div> Weitere Annehmlichkeiten dieses Hauses sind ein geräumiger Hobbyraum, ein Fitnessraum und eine Sauna, sowie eine Garage für zwei Autos und ausreichend Stauraum. Die Lage in Berlin Zehlendorf ist ideal, mit einer hervorragenden Anbindung an öffentliche Verkehrsmittel und einer guten Auswahl an Restaurants, Geschäften und Freizeitmöglichkeiten in der Nähe.
            </div>
            </br> 
            <div> Insgesamt ist dieses luxuriöse Einfamilienhaus in Berlin Zehlendorf ein perfektes Zuhause für anspruchsvolle Familien, die auf der Suche nach einem gehobenen Lebensstil sind, der Stil, Komfort und Entspannung in einer der besten Lagen der Stadt bietet.
            </div >
            `,
            "titleImg": "./public_images/projects/begej-priv/10.jpeg",
            "images": [
                "./public_images/projects/begej-priv/10.jpeg",
                "./public_images/projects/begej-priv/09.jpeg",
                "./public_images/projects/begej-priv/08.jpeg",
                "./public_images/projects/begej-priv/07.jpeg",
                "./public_images/projects/begej-priv/06.jpeg",
                "./public_images/projects/begej-priv/05.jpeg",
                "./public_images/projects/begej-priv/04.jpeg",
                "./public_images/projects/begej-priv/03.jpeg",
                "./public_images/projects/begej-priv/02.jpeg",
                "./public_images/projects/begej-priv/01.jpeg"
            ]
        },
        {
            "title": "Einfamilienhaus in Berlin Steglitz",
            "status": "Verkauft",
            "description": `
            <div> Das Einfamilienhaus in Berlin Steglitz ist ein wunderschönes, modernes Zuhause mit zwei Stockwerken und einem großen Garten. Von außen beeindruckt es mit seiner weiß gestrichenen Fassade und den großen Fenstern, die viel Licht in das Innere des Hauses lassen.
            </div>
            </br>  
            <div> Im Erdgeschoss befinden sich der geräumige Wohnbereich und die moderne Küche, die mit hochwertigen Geräten ausgestattet ist. Der Wohnbereich ist mit bequemen Sofas und Sesseln eingerichtet und verfügt über einen gemütlichen Kamin, der an kalten Wintertagen für wohlige Wärme sorgt. Von hier aus hat man auch direkten Zugang zur großen Terrasse, die einen herrlichen Blick auf den Garten bietet.
            </div>
            </br>  
            <div> Im oberen Stockwerk befinden sich die Schlafzimmer und das Badezimmer. Die Schlafzimmer sind geräumig und komfortabel eingerichtet, jedes mit großen Fenstern, die viel Licht hereinlassen. Das Master-Schlafzimmer hat auch einen eigenen Balkon mit Blick auf den Garten. Das Badezimmer ist modern und elegant gestaltet, mit begehbaren Duschen und stilvollen Armaturen.
            </div> 
            </br> 
            <div> Der Garten ist ein wahrer Traum, mit üppigem Grün und vielen blühenden Sträuchern und Blumen. Es gibt eine große Rasenfläche, die perfekt zum Spielen und Entspannen geeignet ist. Die Garage bietet zusätzlichen Stauraum und kann auch als Arbeitszimmer genutzt werden.
            </div>
            </br> 
            <div> Das Haus befindet sich in einer ruhigen Wohngegend von Steglitz, in der Nähe von Parks, Schulen und Einkaufsmöglichkeiten. Es ist ein perfektes Zuhause für eine Familie, die den Komfort und die Ruhe eines Vorortes genießen möchte, aber dennoch in der Nähe des pulsierenden Lebens der Stadt sein will.
            </div >
            `,
            "titleImg": "./public_images/projects/diekmeier-priv/1.jpg",
            "images": [
                "./public_images/projects/diekmeier-priv/1.jpg",
                "./public_images/projects/diekmeier-priv/2.jpg",
                "./public_images/projects/diekmeier-priv/3.jpg",
            ]
        }
    ]
}