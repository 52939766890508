import React, { useRef, useEffect, useState } from "react";
import { Box, Grid, Divider } from "@mui/material";
import { Text } from "@chakra-ui/react";

import backgroundImg from "../resources/images/generated/background-white-v1.png";

const Impressum = (props) => {
  const windowSize = useRef([window.innerWidth, window.innerHeight]);
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const body = document.body,
    html = document.documentElement;

  const scrollHeight = Math.max(
    body.scrollHeight,
    body.offsetHeight,
    html.clientHeight,
    html.scrollHeight,
    html.offsetHeight
  );

  useEffect(() => {
    setIsSmallScreen(windowSize.current[0] < 1200); // width >900
  }, [windowSize]);

  return (
    <Grid container style={{ marginTop: "0em", display: "flex" }}>
      <Grid
        item
        xs={12}
        md={12}
        alignItems={"center"}
        alignContent={"center"}
      >
        <Grid
          container
          style={{ marginTop: "0em" }}
          spacing={0}
          direction="column"
          alignItems="center"
          justifyContent="center"
        >
          <Grid item style={{ height: "5vh" }}></Grid>
          <Grid
            item
            style={{
              backgroundColor: "white", // Set background to white
              padding: "3em",
              borderRadius: "3px",
              width: "80%",
              color: "black", // Set text color to black
            }}
          >
            <Grid container spacing={3}>
              <Grid item id="mission" xs={12}>
                <Text fontSize="lg" fontWeight="bold" ref={props.missionRef}>
                  Impressum
                </Text>
                <Divider
                  style={{
                    backgroundColor: "lightgrey",
                    margin: "5px 0px 20px 0px",
                  }}
                />
                <Grid container spacing={3}>
                  <Grid item xs={12} lg={12}>
                    <Text fontSize="lg" mb={2}>
                      BDG Wohnbau GmbH <br />
                      Persantestr. 18<br />
                      14167 Berlin <br />
                      Deutschland
                    </Text>
                    <br />
                    <Text fontSize="md" mb={4}>
                      Vertretungsberechtigter Geschäftsführer: Kevin Golanko{" "}
                      <br />
                      Registergericht: Amtsgericht Charlottenburg <br />
                      Registernummer: in Gründung <br />
                      Umsatzsteuer-Identifikationsnummer gemäß § 27a UStG: DE361125009
                      <br /> <br />
                      Kontakt: <br />
                      Telefon: +49 17643540133 <br />
                      E-Mail: verwaltung@bdg-wohnbau.de
                      <br />
                      <br />
                      Verantwortlich für den Inhalt nach § 55 Abs. 2 RStV:{" "}
                      <br />
                      Kevin Golanko <br />
                      Persantestr. 18 <br />
                      14167 Berlin <br />
                      Deutschland <br />
                      <br />
                      <b>Haftungsausschluss:</b>
                      <br />
                      Alle Inhalte unseres Internetauftritts wurden mit
                      größter Sorgfalt und nach bestem Gewissen erstellt. Für
                      die Richtigkeit, Vollständigkeit und Aktualität der
                      Inhalte können wir jedoch keine Gewähr übernehmen. Als
                      Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene
                      Inhalte auf diesen Seiten nach den allgemeinen Gesetzen
                      verantwortlich. Nach §§ 8 bis 10 TMG sind wir als
                      Diensteanbieter jedoch nicht verpflichtet, übermittelte
                      oder gespeicherte fremde Informationen zu überwachen oder
                      nach Umständen zu forschen, die auf eine rechtswidrige
                      Tätigkeit hinweisen. Verpflichtungen zur Entfernung oder
                      Sperrung der Nutzung von Informationen nach den
                      allgemeinen Gesetzen bleiben hiervon unberührt.
                      <br /> <br />
                      Eine diesbezügliche Haftung ist jedoch erst ab dem
                      Zeitpunkt der Kenntniserlangung einer konkreten
                      Rechtsverletzung möglich. Bei Bekanntwerden von den o.g.
                      Rechtsverletzungen werden wir diese Inhalte unverzüglich
                      entfernen.
                      <br /> <br />
                      <b>Haftungsbeschränkung für externe Links:</b>
                      <br />
                      Unsere Webseite enthält Links auf externe Webseiten
                      Dritter. Auf die Inhalte dieser direkt oder indirekt
                      verlinkten Webseiten haben wir keinen Einfluss. Daher
                      können wir für die „externen Links“ auch keine Gewähr auf
                      Richtigkeit der Inhalte übernehmen. Für die Inhalte der
                      externen Links sind die jeweilige Anbieter oder Betreiber
                      (Urheber) der Seiten verantwortlich.
                      <br />
                      <br />
                      Die externen Links wurden zum Zeitpunkt der Linksetzung
                      auf eventuelle Rechtsverstöße überprüft und waren im
                      Zeitpunkt der Linksetzung frei von rechtswidrigen
                      Inhalten. Eine ständige inhaltliche Überprüfung der
                      externen Links ist ohne konkrete Anhaltspunkte einer
                      Rechtsverletzung nicht möglich. Bei direkten oder
                      indirekten Verlinkungen auf die Webseiten Dritter, die
                      außerhalb unseres Verantwortungsbereichs liegen, würde
                      eine Haftungsverpflichtung ausschließlich in dem Fall nur
                      bestehen, wenn wir von den Inhalten Kenntnis erlangen und
                      es uns technisch möglich und zumutbar wäre, die Nutzung im
                      Falle rechtswidriger Inhalte zu verhindern.
                      <br />
                      <br />
                      Diese Haftungsausschlusserklärung gilt auch innerhalb des
                      eigenen Internetauftrittes „Name Ihrer Domain“ gesetzten
                      Links und Verweise von Fragestellern, Blogeinträgern,
                      Gästen des Diskussionsforums. Für illegale, fehlerhafte
                      oder unvollständige Inhalte und insbesondere für Schäden,
                      die aus der Nutzung oder Nichtnutzung solcherart
                      dargestellten Informationen entstehen, haftet allein der
                      Diensteanbieter der Seite, auf welche verwiesen wurde,
                      nicht derjenige, der über Links auf die jeweilige
                      Veröffentlichung lediglich verweist.
                      <br />
                      <br />
                      Werden uns Rechtsverletzungen bekannt, werden die
                      externen Links durch uns unverzüglich entfernt.
                      <br />
                      <br />
                      <b>Urheberrecht:</b>
                      <br />
                      Die auf unserer Webseite veröffentlichen Inhalte und Werke
                      unterliegen dem deutschen Urheberrecht
                      (http://www.gesetze-im-internet.de/bundesrecht/urhg/gesamt.pdf)
                      . Die Vervielfältigung, Bearbeitung, Verbreitung und jede
                      Art der Verwertung des geistigen Eigentums in ideeller und
                      materieller Sicht des Urhebers außerhalb der Grenzen des
                      Urheberrechtes bedürfen der vorherigen schriftlichen
                      Zustimmung des jeweiligen Urhebers i.S.d.
                      Urhebergesetzes
                      (http://www.gesetze-im-internet.de/bundesrecht/urhg/gesamt.pdf
                      ). Downloads und Kopien dieser Seite sind nur für den
                      privaten und nicht kommerziellen Gebrauch erlaubt. Sind
                      die Inhalte auf unserer Webseite nicht von uns erstellt
                      wurden, sind die Urheberrechte Dritter zu beachten. Die
                      Inhalte Dritter werden als solche kenntlich gemacht.
                      Sollten Sie trotzdem auf eine Urheberrechtsverletzung
                      aufmerksam werden, bitten wir um einen entsprechenden
                      Hinweis. Bei Bekanntwerden von Rechtsverletzungen werden
                      wir derartige Inhalte unverzüglich entfernen.
                      <br />
                      <br />
                      Dieses Impressum wurde freundlicherweise von jurarat.de
                      zur Verfügung gestellt.
                    </Text>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <div
        style={{
          backgroundImage: `url('${backgroundImg}')`,
          backgroundRepeat: "repeat-y",
          backgroundSize: "100%",
          opacity: 0.4,
          width: "100%",
          height: scrollHeight,
          position: "absolute",
          zIndex: "-1",
        }}
      ></div>
    </Grid>
  );
};

export default Impressum;
