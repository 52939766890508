import * as React from 'react';
import { Card, CardActions, CardContent, CardMedia, Tooltip, Box, ImageList, ImageListItem } from '@mui/material';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

// Icons
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import ConstructionIcon from '@mui/icons-material/Construction';
import DesignServicesIcon from '@mui/icons-material/DesignServices';
import CloseIcon from '@mui/icons-material/Close';

export default function MediaCard(props) {
    const [height, setHeight] = React.useState("70vh");
    const [imgCols, setImgCols] = React.useState(3);
    const [width, setWidth] = React.useState("100%");
    const [isLarge, setIsLarge] = React.useState(false);
    const [checked, setChecked] = React.useState([]);
    const [isSmallScreen, setIsSmallScreen] = React.useState(false);
    const [overlayImage, setOverlayImage] = React.useState(null); // State for overlay image
    const windowSize = React.useRef([window.innerWidth, window.innerHeight]);

    React.useEffect(() => {
        console.log(props.project);
        setChecked(props.project.images.map(() => false));
    }, [props.project]);

    React.useEffect(() => {
        setIsSmallScreen(windowSize.current[0] < 1200); // width > 900
    }, [windowSize]);

    React.useEffect(() => {
        if (isSmallScreen) {
            setImgCols(1);
        }
    }, [isSmallScreen]);

    const openOverlay = (image) => {
        setOverlayImage(image);
    };

    const closeOverlay = () => {
        setOverlayImage(null);
    };

    return (
        <>
            {"title" in props.project &&
                <Card style={{ height: height, position: "relative" }} sx={{ overflow: "scroll", width: { width } }}>
                    {/* Green Box for Verfügbar */}
                    {(props.project.status.toLowerCase() === "im bau" || props.project.status.toLowerCase() === "in planung") && (
                        <Box
                        sx={{
                            position: "absolute",
                            top: 10,
                            left: 10,
                            background: "linear-gradient(135deg, #19D86D, #4A9D50)",
                            color: "white",
                            padding: "5px 10px",
                            borderRadius: "5px",
                            fontWeight: "bold",
                            zIndex: 10,
                            boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.3)",
                        }}
                        >
                            Verfügbar
                        </Box>
                    )}

                    <CardMedia
                        component="img"
                        sx={{ width: "100%" }}
                        image={props.project.titleImg}
                        title={props.project.title}
                    />
                    <CardContent>
                        <Typography gutterBottom variant="h5" component="div">
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <Tooltip title={props.project.status}>
                                    {{
                                        'verkauft': <RemoveCircleOutlineIcon style={{ margin: "0px 10px 0px 0px" }} />,
                                        'im bau': <ConstructionIcon style={{ margin: "0px 10px 0px 0px" }} />,
                                        'abgeschlossen': <RemoveCircleOutlineIcon style={{ margin: "0px 10px 0px 0px" }} />,
                                        'in planung': <DesignServicesIcon style={{ margin: "0px 10px 0px 0px" }} />
                                    }[props.project.status.toLowerCase()]}
                                </Tooltip>
                                {props.project.title}
                            </Box>
                        </Typography>
                        <div dangerouslySetInnerHTML={{ __html: props.project.description }} />
                        {isLarge &&
                            <>
                                <div style={{ marginTop: "1em" }} />
                                <Box style={{ display: "flex" }}>
                                    <ImageList sx={{ width: "100%", height: 600 }} cols={imgCols || 3} gap={8}>
                                        {props.project.images.map((item, idx) => (
                                            <ImageListItem key={"img-" + idx}>
                                                <img
                                                    onClick={() => openOverlay(item)} // Open overlay on click
                                                    src={`${item}?w=161&fit=crop&auto=format`}
                                                    srcSet={`${item}?w=161&fit=crop&auto=format&dpr=2 2x`}
                                                    alt={"img-" + idx}
                                                    loading="lazy"
                                                    style={{
                                                        cursor: 'pointer'
                                                    }}
                                                />
                                            </ImageListItem>
                                        ))}
                                    </ImageList>
                                </Box>
                            </>
                        }
                    </CardContent>
                    <CardActions>
                        {!isLarge && props.project.images.length > 0 &&
                            <Button size="small" onClick={() => { setHeight("100%"); props.setCardLG(props.cardLG.map((elem, i) => (i === props.idx ? 12 : elem))); setIsLarge(true); }}>
                                Zeige Galerie
                            </Button>
                        }
                        {isLarge &&
                            <Button size="small" onClick={() => { setHeight("70vh"); props.setCardLG(props.cardLG.map((elem, i) => (i === props.idx ? 6 : elem))); setIsLarge(false); }}>
                                Verstecke Galerie
                            </Button>
                        }
                    </CardActions>
                </Card>
            }

            {/* Overlay for displaying zoomed image */}
            {overlayImage && (
                <div
                    style={{
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        width: '100vw',
                        height: '100vh',
                        backgroundColor: 'rgba(0, 0, 0, 0.8)',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        zIndex: 9999,
                    }}
                    onClick={closeOverlay} // Close overlay on click outside the image
                >
                    <img
                        src={overlayImage}
                        alt="Zoomed"
                        style={{
                            maxWidth: '90%',
                            maxHeight: '90%',
                            objectFit: 'contain',
                            cursor: 'pointer',
                        }}
                        onClick={(e) => e.stopPropagation()} // Prevent overlay close when clicking the image
                    />
                </div>
            )}
        </>
    );
}
